<template>
	<div style="height: 100%">
		<!-- <elt-header title="网络字体" icon="icon-brush" intro="本站字体仅提供非商业使用，如需商用使用请自行去官方购买。因此造成的侵权本站概不负责。使用方法详见" /> -->
    
		<elt-header title="网络字体" icon="icon-brush" intro="为规避潜在风险，本站所使用的字体包仅包含展示内容。如有需要请自行联系官方购买" />


		<div class="content">
			<div style="display: grid; grid-template-columns: repeat(5, calc( calc(100% - 96px) / 5)); grid-gap: 24px; ">
				<div v-for="item in links" class="flex column icon-card" style="border-radius: 8px; padding: 24px; border: 1px solid #36373A; padding-bottom: 0; ">
					
					<div style="font-size: 36px; line-height: 52px; color: #ffffff;" :class="item.class">{{item.text}}</div>

					<div style="margin-top: 24px; border-top: 1px solid #6a6a6a;">
						<div style="margin: 24px 0; color: #ffffff;">

							<div class="flex main-between" style="font-size: 18px;">
								<span style="">{{item['font-family']}}</span>
								<span style="font-weight: 700;">{{item.type}}</span>
							</div>
							<div style="margin-top: 16px" class="flex main-between">
								<div style="font-size: 14px;">
									<div v-if="item.commercial == 0" class="flex cross-center">
										<elt-icon :size="16" icon="icon-warning" style="color: #FFC2D3; margin-right: 6px;"/>
										<span>商用需付费</span>
									</div>
									<div v-else class="flex cross-center">
										<elt-icon :size="16" icon="icon-check_circled" style="color: #FDFFC2; margin-right: 6px;"/>
										<span>可免费商用</span>
									</div>
								</div>
								<div style="">
									<elt-icon :size="20" icon="icon-mobile" style="color: #ffffff;"/>
									<elt-icon :size="20" icon="icon-laptop" style="color: #ffffff; margin-left: 12px;"/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios'

export default {
	data() {
		return {
			links: [],
			// current: 0,
			// navBar: ['设计', '前端', '其他']
		}
	},
	mounted() {
		this.getLinks()
	},
	created() {},
	components: {},
	methods: {
		jump(url) { window.open(url, '_blank') },
		getLinks() {
			let url = process.env.BASE_URL + 'data/fonts/' + 'fonts.json'
			axios.get(url).then( res => {
				this.links = res.data
			})
		},
		switchTab(idx) { this.current = idx }
	}
}
</script>

<style>
/* .content::-webkit-scrollbar{ display: none; } */
</style>